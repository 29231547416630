// import GLightbox from "glightbox";
import $ from "jquery";

import awards from "./modules/awards";
// import initScrollPageToButtons from "./modules/scrollTo";
import sliders from "./modules/sliders";
import about from "./modules/about";
// import onSubmitInit from "./modules/sendContactBtn";
import metrics from "./modules/metrics";
import contacts from "./modules/contacts";
import projects_gallery from "./modules/projects_gallery";
import price from "./modules/price";

// onSubmitInit();
sliders();

awards.init();
metrics.initMetrics();
about.init();
// initScrollPageToButtons();
contacts.init();
projects_gallery();
price.init();
