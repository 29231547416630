import $ from "jquery";

function init() {
  $(".toggle-trigger").on("click", function (e) {
    e.preventDefault();
    const $this = $(this);
    const $parent = $this.parent();
    $parent.addClass("-animating");
    console.log("trigger!");
    $this
      .toggleClass("active")
      .next()
      .slideToggle(500, function () {
        $(this).toggleClass("-shown");
        $parent.removeClass("-animating");
      });
  });

  $(".about .detail").on("click", function (e) {
    e.preventDefault();
    const $this = $(this);
    const $parent = $this.parent();
    $parent.addClass("-animating");
    $this.prev().slideToggle(500, function () {
      $parent.toggleClass("-shown");
      $parent.removeClass("-animating");
    });
  });

  $(".etaps .detail").on("click", function (e) {
    e.preventDefault();
    const $this = $(this);
    $this.toggleClass("active");
    const $parent = $this.parent().parent();
    $parent.addClass("-animating");
    $parent.next().slideToggle(500, function () {
      $parent.toggleClass("-shown");
      $parent.removeClass("-animating");
    });
  });

  $(".project_one_page .detail").on("click", function (e) {
    e.preventDefault();
    const $this = $(this);
    const $parent = $this.parent().parent();
    $parent.addClass("-animating");
    $parent.next().slideToggle(500, function () {
      $parent.toggleClass("-shown");
      $parent.removeClass("-animating");
    });
  });

  $(".question-filter").on("click", function (e) {
    e.preventDefault();
    const $this = $(this);
    $this.siblings().removeClass("-active").end().addClass("-active");
    const filter = $this.data("filter");
    const questions = $(".questions .toggle-widget");
    if (filter === "all") {
      questions.show();
    } else {
      questions.hide().filter(`.all,.${filter}`).show();
    }
  });

  const $window = $(window);

  $window.resize(function () {
    if ($window.width() < 721) {
      $(".blog-grid .blog-item:last").css("display", "none");
    }
    if ($window.width() > 721) {
      $(".blog-grid .blog-item:last").css("display", "block");
    }
  });
}

export default { init };
