import Swiper from "swiper/swiper-bundle.js";
import $ from "jquery";

function init() {
  let awardSwiper = $(".award-swiper");
  if (awardSwiper.length == 0) {
    return;
  }
  let myCaption, mySwiper;

  if ($(".award-caption").length > 0) {
    myCaption = new Swiper(".award-caption", {
      init: true,
      spaceBetween: 0,
      slidesPerView: 1,
      initialSlide: 2,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      simulateTouch: false,
      allowTouchMove: false,
      followFinger: false,
    });
  }

  const allSlides = $(".award-swiper .swiper-slide");

  mySwiper = new Swiper(".award-swiper", {
    init: true,
    effect: "coverflow",
    grabCursor: true,
    centeredSlides: true,
    slidesPerView: "auto",
    initialSlide: 12,
    lazy: true,
    navigation: {
      nextEl: ".award-next",
      prevEl: ".award-prev",
    },
    coverflowEffect: {
      rotate: 10,
      stretch: 10,
      depth: 120,
      modifier: 2,
      slideShadows: false,
    },
    on: {
      slideChange: function () {
        allSlides.removeClass("hide-slide");
        const activeIndex = this.activeIndex;
        allSlides.each(function (index, el) {
          if (Math.abs(index - activeIndex) > 3) {
            el.classList.add("hide-slide");
          }
        });
        myCaption.slideTo(activeIndex);
      },
    },
  });
  // TODO: add media state change listener and disable coverflow effect

  $(".award-slider").on("click", ".swiper-slide", function () {
    const index = $(this).index();
    myCaption.slideTo(index);
    mySwiper.slideTo(index);
  });
  if (mySwiper && mySwiper.slideTo) {
    mySwiper.slideTo(3);
  }
}

export default { init };
