function init() {
  if (!window.mapboxgl) return;

  mapboxgl.accessToken =
    "pk.eyJ1IjoiYXN5YS1seWFub3ZhIiwiYSI6ImNraXExejlnNDFxZm0yc2xiaXZ6czFybTQifQ.F3rsCidFjSBiIeMVSd_etA";
  var map = new mapboxgl.Map({
    container: "map",
    style: "mapbox://styles/asya-lyanova/ckiq20xvu4wst17peayy2yowk",
    center: [30.3236618, 59.9057232],
    zoom: 15,
  });
  map.scrollZoom.disable();
  map.addControl(new mapboxgl.NavigationControl());

  const el = document.createElement("div");
  el.className = "map-marker";
  el.style.width = "168px";
  el.style.height = "240px";

  var marker = new mapboxgl.Marker(el)
    .setLngLat([30.3236618, 59.9057232])
    .addTo(map);
}

export default { init };
