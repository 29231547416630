import $ from "jquery";

function init() {
  const $radio1 = $(".radio-1"),
    $radio2 = $(".radio-2");
  let calcType = "design"; // 'plan'

  const $area = $(".area_input"),
    $section = $(".service-price"),
    $rangeForm = $(".range_form"),
    $pricePlan = $(".price_plan"),
    $pricesPerMeter = {
      comfort: $(".ppm-comfort"),
      premium: $(".ppm-premium"),
      exclusive: $(".ppm-exclusive"),
    },
    $pricesPerMeterOriginal = {
      comfort: $(".ppm-comfort-original span"),
      premium: $(".ppm-premium-original span"),
      exclusive: $(".ppm-exclusive-original span"),
    },
    $discount = {
      comfort: $(".discount-comfort"),
      premium: $(".discount-premium"),
      exclusive: $(".discount-exclusive"),
    },
    $discounts = $(".discount-info"),
    $pricesTotal = {
      comfort: $(".price-comfort"),
      premium: $(".price-premium"),
      exclusive: $(".price-exclusive"),
    },
    $priceComfort = $(".price-comfort"),
    $pricePremium = $(".price-premium"),
    $priceExclusive = $(".price-exclusive");

  $("#radio-1").on("click", function (e) {
    calcType = "plan";
    $radio1.show();
    $radio2.hide();
    updatePrices($rangeForm.val());
  });

  $("#radio-2").on("click", function (e) {
    calcType = "design";
    $radio2.show();
    $radio1.hide();
    updatePrices($rangeForm.val());
  });

  $("[data-square]").on("click", function (e) {
    e.preventDefault();
    const square = $(this).data("square");
    $area.val(square);
    $rangeForm.val(square);
    updatePrices(square);
  });

  const prices = window.pricetable;
  const direction = prices.direction;
  const discounts = prices.discounts;
  const formatter = new Intl.NumberFormat("ru-RU");

  function updateTarifs(square) {
    if (square >= 1000) {
      $section.addClass("-individual");
      tarifs.forEach((tarif) => {
        //
        $pricesTotal[tarif].html("Индивидуально");
        $pricesPerMeter[tarif].html("Индивидуально");
        $pricesPerMeterOriginal[tarif].html("Индивидуально");
      });
      return;
    }
    $section.removeClass("-individual");
    const tarifs = ["comfort", "premium", "exclusive"];
    const rangeStarts = Object.keys(prices[tarifs[0]]).map((one) =>
      parseInt(one)
    );
    // ищем нужный диапазон
    let i = 0;
    for (; i < rangeStarts.length; i++) {
      if (square >= rangeStarts[i] && square < rangeStarts[i + 1]) {
        break;
      }
    }
    if (i == rangeStarts.length) i--;
    tarifs.forEach((tarif) => {
      const price = prices[tarif][rangeStarts[i]];
      const original = Math.max(...Object.values(prices[tarif]));
      $pricesTotal[tarif].html(formatter.format(square * price));
      $pricesPerMeter[tarif].html(formatter.format(price));
      $pricesPerMeterOriginal[tarif].html(formatter.format(original));
      const discount = discounts[rangeStarts[i]];
      if (discount == 0) {
        $discounts.css({ opacity: 0 });
        // $discount[tarif].html(`${discount}%`);
      } else {
        $discounts.css({ opacity: 1 });
        $discount[tarif].html(`${discount}%`);
      }
    });
  }

  function updatePrices(square) {
    if (calcType == "plan") {
      $pricePlan.removeClass("-top");
      let price_plan;

      if (square < 100) {
        price_plan = "от 50 000";
        if (direction == "life" && square < 90) {
          price_plan = "40 000";
        }
      } else if (square < 300) {
        price_plan = "от 60 000";
      } else if (square < 500) {
        price_plan = "от 70 000";
      } else if (square < 1000) {
        price_plan = "от 80 000";
      } else {
        price_plan = "рассчитывается индивидуально";
        $pricePlan.addClass("-top");
      }
      $pricePlan.html(price_plan);
    } else {
      updateTarifs(square);
    }
  }

  $rangeForm.on("input change", function () {
    const val = parseInt($(this).val());
    $area.val(val);
    updatePrices(val);
  });

  $area.on("input change", function () {
    const val = parseInt($(this).val());
    $rangeForm.val(val);
    updatePrices(val);
    $area.val(val);
  });

  updateTarifs(150);
}

export default { init };
