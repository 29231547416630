import $ from "jquery";

export default function projects_gallery() {
  const projectItems = $(".js-project");

  $(".switch-projects").on("click", function (e) {
    e.preventDefault();
    const showSelector = $(this).data("show");
    projectItems.not(showSelector).hide();
    $(showSelector).show();
  });
}
