import $ from "jquery";
import slick from "slick-carousel";

function homeHeroSlider() {
  let el = $(".home-slider");
  if (el.length == 0) {
    return;
  }
  el.slick({
    arrows: true,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    swipe: true,
    dots: true,
    pauseOnHover: false,
    pauseOnFocus: false,
  });
}

function serviceTypesSlider() {
  let els = $(".service_type-slider");
  if (els.length == 0) {
    return;
  }
  els.each(function (index, item) {
    $(item).slick({
      arrows: true,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      swipe: true,
      dots: true,
      pauseOnHover: false,
      pauseOnFocus: false,
    });
  });
}

function projectSlider() {
  let el = $(".project-slider");
  if (el.length == 0) {
    return;
  }
  el.slick({
    arrows: true,
    //autoplay: true,
    autoplaySpeed: 5000,
    swipe: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    centerMode: true,
    focusOnSelect: true,
    variableWidth: true,
  });
}

function homePrizesSlider() {
  let el = $(".prize-slider");
  if (el.length == 0) {
    return;
  }
  el.slick({
    infinite: false,
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,
    variableWidth: true,
  });
}

function homeClientsSlider() {
  let el = $(".clients-slider");
  if (el.length == 0) {
    return;
  }
  el.slick({
    infinite: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          variableWidth: false,
        },
      },
      {
        breakpoint: 1080,
        settings: {
          variableWidth: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 960,
        settings: {
          variableWidth: false,
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      //{
      //breakpoint: 600,
      //settings: {
      //variableWidth: false,
      //slidesToShow: 1,
      //slidesToScroll: 1,
      //},
      //},
    ],
  });

  const clientTypesButtons = $(".type_clients .button");
  const clientHeadings = $(".clients .section-header p");
  const clientSliders = $(".clients-slider");

  clientTypesButtons.on("click", function (e) {
    e.preventDefault();
    clientTypesButtons.removeClass("active");
    const $this = $(this);
    const type = $this.hasClass("-public") ? ".-public" : ".-private";
    $this.addClass("active");
    clientHeadings.removeClass("active");
    clientHeadings.filter(type).addClass("active");
    clientSliders.removeClass("active").filter(type).addClass("active");
  });
}

function menu() {
  $("#openMenu").on("click", function (e) {
    const $this = $(this);
    if ($this.hasClass("act")) {
      $this.removeClass("act");
      $("#menu-glavnoe-menyu").slideUp();
    } else {
      $this.addClass("act");
      $("#menu-glavnoe-menyu").slideDown();
    }
    return false;
  });
}

$(function () {
  var elWrap = $(".buklet-slider");
  if (elWrap.length == 0) {
    return;
  }
  var el = elWrap.find("img"),
    indexImg = 1,
    indexMax = el.length;

  function change() {
    el.fadeOut(1);
    el.filter(":nth-child(" + indexImg + ")").fadeIn(1);
  }

  function autoChange() {
    indexImg++;
    if (indexImg > indexMax) {
      indexImg = 1;
    }
    change();
  }
  var interval = setInterval(autoChange, 5000);

  elWrap.mouseover(function () {
    clearInterval(interval);
  });
  elWrap.mouseout(function () {
    interval = setInterval(autoChange, 5000);
  });

  elWrap.append('<span class="next"></span><span class="prev"></span>');

  $("span.next").click(function () {
    indexImg++;
    if (indexImg > indexMax) {
      indexImg = 1;
    }
    change();
  });
  $("span.prev").click(function () {
    indexImg--;
    if (indexImg < 1) {
      indexImg = indexMax;
    }
    change();
  });
});

function sliders() {
  homeHeroSlider();
  projectSlider();
  homePrizesSlider();
  homeClientsSlider();
  serviceTypesSlider();
  menu();
}

export default sliders;
